import React from "react";
import classes from "./Main.module.css";
import GameCategoryList from "../components/game_category_list/GameCategoryList";
import {allCategories, manyGames} from "../data/Data";
import SideMenu from "./SideMenu";

export default function MainScreen() {
    return (
        <div className={classes.main}>
            <SideMenu/>
            <div className={classes.main_content}>
                <GameCategoryList
                    title={"Trending now"}
                    games={manyGames}
                    gridItemSize="big"
                />
                {
                    allCategories().map(item =>
                        <GameCategoryList
                            title={item.name}
                            games={manyGames}
                            gridItemSize={item.gridItemSize}
                        />
                    )
                }
            </div>
        </div>
    );
}