import {Unity, useUnityContext} from "react-unity-webgl";
import React, {useEffect, useState} from "react";
import classes from "./FreeCell.module.css";

// eslint-disable-next-line no-unused-vars
export default function FreeCell() {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "/build/freecell/freecell_webgl.loader.js",
        dataUrl: "/build/freecell/freecell_webgl.data",
        frameworkUrl: "/build/freecell/freecell_webgl.framework.js",
        codeUrl: "/build/freecell/freecell_webgl.wasm",
    });

    // We'll round the loading progression to a whole number to represent the
    // percentage of the Unity Application that has loaded.
    const loadingPercentage = Math.round(loadingProgression * 100);

    // We'll use a state to store the device pixel ratio.
    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );

    useEffect(
        function () {
            // A function which will update the device pixel ratio of the Unity
            // Application to match the device pixel ratio of the browser.
            const updateDevicePixelRatio = function () {
                setDevicePixelRatio(window.devicePixelRatio);
            };
            // A media matcher which watches for changes in the device pixel ratio.
            const mediaMatcher = window.matchMedia(
                `screen and (resolution: ${devicePixelRatio}dppx)`
            );
            // Adding an event listener to the media matcher which will update the
            // device pixel ratio of the Unity Application when the device pixel
            // ratio changes.
            mediaMatcher.addEventListener("change", updateDevicePixelRatio);
            return function () {
                // Removing the event listener when the component unmounts.
                mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
            };
        },
        [devicePixelRatio]
    );

    return (
        <div className={classes.container}>
            {isLoaded === false && (
                // We'll conditionally render the loading overlay if the Unity
                // Application is not loaded.
                <div className={classes.loading_overlay}>
                    <p>Loading... ({loadingPercentage}%)</p>
                </div>
            )}
            <Unity className={classes.unity} unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} />
        </div>
    );

}