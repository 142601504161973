import {Link, useParams} from "react-router-dom";
import React from "react";

export default function Game(props) {
    const params = useParams()
    console.log(params.gameId)
    return (
        <div>
            Game
            <Link to={"/"}>To main</Link>
        </div>
    );
}