import classes from "./Main.module.css";
import SideMenu from "./SideMenu";
import GameCategoryList from "../components/game_category_list/GameCategoryList";
import gamesForCategory, {allCategories} from "../data/Data";
import React from "react";
import {useParams} from "react-router-dom";

export default function CategoryScreen() {
    const categoryId = useParams().categoryId;
    console.log(categoryId)
    const categoryData = allCategories().find(data => (
        data.id === categoryId
    ))
    return (
        <div className={classes.main}>
            <SideMenu/>
            <div className={classes.main_content}>
                <GameCategoryList
                    title={categoryData.name}
                    games={gamesForCategory(categoryData.id)}
                    gridItemSize={"big"}
                />
            </div>
        </div>
    );
}