import classes from "./Main.module.css";
import SideMenu from "./SideMenu";
import React from "react";

export default function ContactUsScreen() {
    return (
        <div className={classes.main}>
            <SideMenu/>
            <div className={classes.main_content}>
                <div className={classes.contact_us}>
                    Здесь можно будет с нами связаться
                </div>
            </div>
        </div>
    );
}