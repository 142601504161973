import classes from "./GameCategoryList.module.css";
import GameGridItem from "../game_grid_item/GameGridItem";
import React from "react";

export default function GameCategoryList(props) {
    return (
        <div className={classes.games_category_list}>
            <div className={classes.games_category_list_title}>
                {props.title}
            </div>
            <div className={classes.games_list}>
                {
                    props.games.map(gameData =>
                        <GameGridItem
                            key={gameData.id}
                            gameData={gameData}
                            size={props.gridItemSize}
                        />
                    )
                }
            </div>
        </div>
    );
}