import classes from "./GameGridItem.module.css";
import {Link} from "react-router-dom";
import React from "react";

export default function GameGridItem(props) {
    const size = props.size
    let sizeClassName;
    if (size === "big") {
        sizeClassName = classes.big
    } else if (size === "medium") {
        sizeClassName = classes.medium
    } else if (size === "small") {
        sizeClassName = classes.small
    }


    return (
        <div className={classes.game_grid_item + " " + sizeClassName}>
            <Link to={props.gameData.route}>
                <img src={props.gameData.img} alt={props.gameData.title}/>
                <div className={classes.game_grid_item_overlay}>
                    <div className={classes.game_grid_item_title}>
                        {props.gameData.title}
                    </div>
                </div>
            </Link>
        </div>
    );
}