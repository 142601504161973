import './App.css';

import React from "react";
import {RouterProvider} from "react-router-dom";
import createAppRouter from "./Router";

function App() {
    return (
        <RouterProvider router={createAppRouter()}/>
    );
}

export default App;
