import {createBrowserRouter, Outlet} from "react-router-dom";
import ErrorPage from "./ui/ErrorPage";
import MainScreen from "./ui/MainScreen";
import Game from "./ui/Game";
import CategoryScreen from "./ui/CategoryScreen";
import React from "react";
import PrivacyPolicyScreen from "./ui/PrivacyPolicyScreen";
import ContactUsScreen from "./ui/ContactUsScreen";
import GameScreen from "./ui/GameScreen";

function Root() {
    return (
        <Outlet style={{width: "100%", height: "100%"}}/>
    );
}

export function mainRoute() {
    return "/";
}

export function categoryRoute(categoryId) {
    return "/categories/" + categoryId;
}

export function gameRoute(gameId) {
    return "/games/" + gameId;
}

export function privacyPolicyRoute() {
    return "/privacy_policy"
}

export function contactUsRoute() {
    return "/contact_us"
}
export default function createAppRouter() {
    return createBrowserRouter([
            {
                path: "/",
                element: <Root/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        index: true,
                        element: <MainScreen/>
                    },
                    {
                        path: "games/:gameId",
                        element: <GameScreen/>,
                    },
                    {
                        path: "categories/:categoryId",
                        element: <CategoryScreen/>
                    },
                    {
                        path: "privacy_policy",
                        element: <PrivacyPolicyScreen/>
                    },
                    {
                        path: "contact_us",
                        element: <ContactUsScreen/>
                    }
                ],
            },
            {
                path: "game",
                element: <Game/>
            }
        ]
    );
}