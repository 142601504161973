import classes from "./Main.module.css";
import {additionalMenuItems, mainMenuCategories, menuItems} from "../data/Data";
import {Link, NavLink} from "react-router-dom";
import React from "react";
import {categoryRoute} from "../Router";
import Logo from "../components/logo/Logo";
import styles from "./Main.module.css";

export default function SideMenu() {
    return (
        <div className={classes.nav_panel}>
            <Logo/>
            <input id={styles.menu_toggle} type="checkbox"/>
            <label className={classes.menu_button_container} htmlFor={styles.menu_toggle}>
                <div className={classes.menu_button}></div>
            </label>
            <div className={classes.menu_lists_container}>
                <ul className={classes.top_menu_list}>
                    {menuItems.map(item =>
                        <li key={item.name}>
                            <NavLink to={item.route}
                                 className={({ isActive, isPending }) =>
                                     isPending ? "pending" : isActive ? classes.active : ""
                                 }>
                                {item.name}
                            </NavLink>
                        </li>
                    )}
                </ul>
                <ul className={classes.categories_list}>
                    {mainMenuCategories().map(item =>
                        <li key={item.name}>
                            <NavLink to={categoryRoute(item.id)}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? classes.active : ""
                                }>
                                 {item.name}
                            </NavLink>
                        </li>)
                    }
                </ul>
                <ul className={classes.additional_menu_list}>
                    {additionalMenuItems.map(item =>
                        <li key={item.name}>
                            <Link to={item.route}>{item.name}</Link>
                        </li>
                    )}
                </ul>
                <div className={classes.copyright}>© 2024 MnogoIgor</div>
            </div>
        </div>
    );
}