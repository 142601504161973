import classes from "./Main.module.css";
import SideMenu from "./SideMenu";
import React from "react";

export default function PrivacyPolicyScreen() {
    return (
        <div className={classes.main}>
            <SideMenu/>
            <div className={classes.main_content}>
                <div className={classes.privacy_policy}>
                    Privacy policy - у нас все серьезно
                </div>
            </div>
        </div>
    );
}