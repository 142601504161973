import classes from "./Logo.module.css";
import {Link} from "react-router-dom";
import {mainRoute} from "../../Router";
import React from "react";

export default function Logo() {
    return (
        <div className={classes.logo}>
            <Link to={mainRoute()}>MnogoIgor</Link>
        </div>
    );
}