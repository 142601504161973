import {categoryRoute, contactUsRoute, gameRoute, mainRoute, privacyPolicyRoute} from "../Router";

export const freecellGameData = {
    title: "Freecell",
    img: "/images/freecell.png",
    route: gameRoute("freecell"),
    id: "freecell",
    description: "The exposed card at the end of each tableau column is available for play. You may move it to an empty FreeCell at any time to release the card beneath, but it's a good idea to keep the FreeCells empty for as long as possible. As each Ace becomes available it may be transferred to one of the HomeCells. The HomeCells are built up in ascending suit sequence to the King. You may move an exposed card from end of a tableau column or from a FreeCell to another tableau column if it will form a descending sequence of alternating colours, e.g. 6 on 7 or Q on K just like in Klondike. Empty spaces in the Tableau can be filled by any card you like, but don't waste them as empty tableau spaces are very powerful as we will see later. You keep moving single cards, one at a time, until the game either blocks or comes out."
}

const trendingCategoryData = {
    name: "Trending now",
    gridItemSize: "small",
    id: "trending"
}
export const menuItems = [
    {
        name: "All games",
        route: mainRoute()
    },
    {
        name: trendingCategoryData.name,
        route: categoryRoute(trendingCategoryData.id)
    },
    {
        name: "Random game",
        route: freecellGameData.route
    }
];

const categoriesData = [
    {
        name: "Cards",
        gridItemSize: "medium",
        id: "cards"
    },
    {
        name: "Educational",
        gridItemSize: "small",
        id: "educational"
    },
    {
        name: "Quiz",
        gridItemSize: "medium",
        id: "quiz"
    },
    {
        name: "For kids",
        gridItemSize: "small",
        id: "for_kids"
    },
    {
        name: "Casual",
        gridItemSize: "small",
        id: "casual"
    },
    trendingCategoryData
]

export function allCategories() {
    return categoriesData
}

export function mainMenuCategories() {
    return categoriesData.filter(item => item.id !== trendingCategoryData.id)
}

export const additionalMenuItems = [
    {
        name: "Privacy policy",
        route: privacyPolicyRoute()
    },
    {
        name: "Contact us",
        route: contactUsRoute()
    }
];

export const manyGames = Array(10).fill(freecellGameData)

export default function gamesForCategory(categoryId) {
    return manyGames
}

export function gameById(id) {
    console.log("comparing " + freecellGameData.id + " and " + id)
    if (freecellGameData.id === id) {
        return freecellGameData
    } else {
        throw 404;
    }
}