import Logo from "../components/logo/Logo";
import {useParams} from "react-router-dom";
import {gameById} from "../data/Data";
import classes from "./GameScreen.module.css";
import {useState} from "react";
import FreeCell from "./games/FreeCell";

function GameInfo(props) {
    return (
        <div className={classes.card}>
            <div className={classes.top_container}>
                <div className={classes.title}>{props.gameData.title}</div>
                <div className={classes.preview}>
                    <img
                        className={classes.image}
                        src={props.gameData.img}
                        alt={props.gameData.title}
                    />
                </div>
                <div className={classes.button} onClick={props.onStart}>Play now</div>
                <div className={classes.bottom_container}>
                    <div className={classes.description_header}>About: {props.gameData.title}</div>
                    <div className={classes.description}>{props.gameData.description}</div>
                </div>
            </div>
        </div>
    );
}

function TheGame() {
    return (
        <div className={classes.game}>
            <FreeCell/>
        </div>
    );
}

export default function GameScreen() {
    const {gameId} = useParams()
    console.log(gameId)
    const gameData = gameById(gameId)

    const [gameStarted, setGameStarted] = useState(false)

    function onStart() {
        setGameStarted(true)
    }
    return (
        <div className={classes.container}>
            <div className={classes.logo}>
                <Logo/>
            </div>
            {
                gameStarted ?
                    (TheGame()) :
                    (<GameInfo gameData={gameData} onStart={onStart}/>)
            }
        </div>
    )
}